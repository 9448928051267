import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="66"
      viewBox="0 0 240 66"
      fill="none"
    >
      <path
        d="M13.2523 14.9602C11.7694 16.4413 10.9364 18.4501 10.9364 20.5448C10.9364 22.6394 11.7694 24.6482 13.2523 26.1293C14.7351 27.6105 16.7462 28.4425 18.8433 28.4425C20.9403 28.4425 22.9515 27.6105 24.4343 26.1293C25.9171 24.6482 26.7502 22.6394 26.7502 20.5448C26.7502 18.4501 25.9171 16.4413 24.4343 14.9602C22.9515 13.4791 20.9403 12.647 18.8433 12.647C16.7462 12.647 14.7351 13.4791 13.2523 14.9602Z"
        fill="#121212"
      />
      <path
        d="M1.87616 6.2312C1.96818 5.03612 2.91884 4.08654 4.11531 3.99462L27.5428 2.19475C28.2509 2.14035 28.9475 2.39773 29.4497 2.89935L54.6427 28.0633C55.5928 29.0123 55.5928 30.5509 54.6427 31.4999L29.4108 56.7028C28.4606 57.6518 26.9202 57.6518 25.9701 56.7027L0.779521 31.5389C0.277378 31.0373 0.0197314 30.3416 0.0741886 29.6343L1.87616 6.2312ZM13.3541 26.2048C14.058 26.9194 14.8966 27.4876 15.8215 27.8767C16.7465 28.2659 17.7393 28.4683 18.743 28.4723C19.7466 28.4762 20.741 28.2817 21.669 27.8998C22.597 27.518 23.44 26.9564 24.1496 26.2475C24.8592 25.5385 25.4213 24.6963 25.8033 23.7693C26.1854 22.8423 26.3799 21.849 26.3757 20.8465C26.3715 19.8441 26.1687 18.8524 25.7788 17.9286C25.389 17.0049 24.8199 16.1673 24.1044 15.4644C22.6745 14.0595 20.7468 13.276 18.7412 13.2844C16.7355 13.2928 14.8145 14.0924 13.3964 15.5092C11.9784 16.9259 11.1782 18.845 11.1703 20.8483C11.1624 22.8516 11.9473 24.7769 13.3541 26.2048Z"
        fill="#FF4F4B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.0377 0.533816C36.7503 -0.177939 37.9056 -0.177939 38.6182 0.533816L66.5964 28.4798C67.309 29.1915 67.309 30.3455 66.5964 31.0573L42.2675 55.3581C41.555 56.0699 40.3997 56.0699 39.6871 55.3581C38.9745 54.6464 38.9745 53.4924 39.6871 52.7806L62.7257 29.7685L36.0377 3.11131C35.3252 2.39955 35.3252 1.24557 36.0377 0.533816Z"
        fill="#121212"
      />
      <path
        d="M94.2986 7.02655C93.8252 6.11474 93.1676 5.43089 92.326 4.97499C91.4843 4.50155 90.5111 4.26483 89.4064 4.26483C88.1965 4.26483 87.1181 4.53662 86.1713 5.08019C85.2244 5.62377 84.4792 6.3953 83.9356 7.39478C83.4096 8.39426 83.1465 9.55155 83.1465 10.8667C83.1465 12.1818 83.4096 13.3478 83.9356 14.3648C84.4792 15.3643 85.2244 16.1358 86.1713 16.6794C87.1181 17.223 88.1965 17.4948 89.4064 17.4948C91.0372 17.4948 92.361 17.0389 93.378 16.1271C94.3951 15.2153 95.0175 13.9791 95.2455 12.4185H88.3806V10.025H98.4543V12.3659C98.2615 13.7862 97.753 15.0925 96.9288 16.2849C96.1222 17.4772 95.0614 18.4329 93.7463 19.1518C92.4487 19.8532 91.0021 20.2039 89.4064 20.2039C87.688 20.2039 86.1187 19.8094 84.6983 19.0203C83.278 18.2137 82.147 17.1002 81.3054 15.6799C80.4813 14.2596 80.0692 12.6552 80.0692 10.8667C80.0692 9.07811 80.4813 7.47368 81.3054 6.05337C82.147 4.63306 83.278 3.52837 84.6983 2.73931C86.1362 1.93271 87.7056 1.52941 89.4064 1.52941C91.3528 1.52941 93.08 2.01162 94.5879 2.97603C96.1135 3.9229 97.2182 5.27308 97.902 7.02655H94.2986Z"
        fill="#121212"
      />
      <path
        d="M103.582 9.72251C103.947 9.1088 104.429 8.6339 105.028 8.29782C105.642 7.94712 106.365 7.77177 107.198 7.77177V10.3581H106.563C105.583 10.3581 104.838 10.6066 104.327 11.1034C103.83 11.6002 103.582 12.4623 103.582 13.6897V20.0461H101.083V7.96904H103.582V9.72251Z"
        fill="#121212"
      />
      <path
        d="M120.564 13.7117C120.564 14.1646 120.535 14.5738 120.476 14.9391H111.248C111.321 15.9035 111.679 16.678 112.322 17.2624C112.965 17.8469 113.754 18.1392 114.69 18.1392C116.034 18.1392 116.984 17.5766 117.539 16.4515H120.235C119.87 17.562 119.205 18.4753 118.24 19.1913C117.291 19.8927 116.107 20.2433 114.69 20.2433C113.535 20.2433 112.498 19.9876 111.577 19.4762C110.671 18.9502 109.955 18.2195 109.429 17.2844C108.918 16.3346 108.662 15.2386 108.662 13.9966C108.662 12.7546 108.91 11.6659 109.407 10.7308C109.919 9.78096 110.627 9.05035 111.533 8.53892C112.454 8.02749 113.506 7.77177 114.69 7.77177C115.829 7.77177 116.845 8.02018 117.736 8.517C118.628 9.01382 119.322 9.71521 119.819 10.6212C120.315 11.5125 120.564 12.5427 120.564 13.7117ZM117.955 12.9226C117.941 12.002 117.612 11.2641 116.969 10.7088C116.326 10.1536 115.53 9.87594 114.58 9.87594C113.718 9.87594 112.98 10.1536 112.366 10.7088C111.753 11.2495 111.387 11.9874 111.27 12.9226H117.955Z"
        fill="#121212"
      />
      <path
        d="M122.19 13.9528C122.19 12.7399 122.438 11.6659 122.935 10.7308C123.446 9.79557 124.133 9.07227 124.995 8.56084C125.872 8.03479 126.837 7.77177 127.889 7.77177C128.838 7.77177 129.664 7.96173 130.365 8.34165C131.081 8.70696 131.651 9.16725 132.075 9.72251V7.96904H134.596V20.0461H132.075V18.2488C131.651 18.8187 131.074 19.2936 130.343 19.6735C129.613 20.0534 128.78 20.2433 127.845 20.2433C126.807 20.2433 125.857 19.9803 124.995 19.4543C124.133 18.9136 123.446 18.1684 122.935 17.2186C122.438 16.2542 122.19 15.1656 122.19 13.9528ZM132.075 13.9966C132.075 13.1637 131.9 12.4404 131.549 11.8267C131.213 11.213 130.767 10.7454 130.212 10.4239C129.657 10.1024 129.058 9.9417 128.415 9.9417C127.772 9.9417 127.173 10.1024 126.617 10.4239C126.062 10.7308 125.609 11.191 125.258 11.8048C124.922 12.4039 124.754 13.1199 124.754 13.9528C124.754 14.7857 124.922 15.5163 125.258 16.1446C125.609 16.7729 126.062 17.2551 126.617 17.5912C127.187 17.9127 127.786 18.0734 128.415 18.0734C129.058 18.0734 129.657 17.9127 130.212 17.5912C130.767 17.2698 131.213 16.8022 131.549 16.1884C131.9 15.5601 132.075 14.8295 132.075 13.9966Z"
        fill="#121212"
      />
      <path
        d="M140.793 10.0075V16.6926C140.793 17.1455 140.895 17.4743 141.1 17.6789C141.319 17.8689 141.684 17.9638 142.195 17.9638H143.73V20.0461H141.757C140.632 20.0461 139.77 19.7831 139.171 19.257C138.572 18.731 138.272 17.8762 138.272 16.6926V10.0075H136.847V7.96904H138.272V4.96622H140.793V7.96904H143.73V10.0075H140.793Z"
        fill="#121212"
      />
      <path
        d="M152.847 20.2302C151.62 20.2302 150.515 20.0198 149.533 19.5989C148.551 19.1606 147.78 18.5469 147.218 17.7578C146.657 16.9687 146.377 16.0482 146.377 14.9961H149.586C149.656 15.7851 149.963 16.4339 150.506 16.9424C151.067 17.4509 151.848 17.7052 152.847 17.7052C153.882 17.7052 154.688 17.4597 155.267 16.9687C155.845 16.4602 156.135 15.8114 156.135 15.0224C156.135 14.4087 155.951 13.9089 155.582 13.5232C155.232 13.1374 154.785 12.8393 154.241 12.6289C153.715 12.4185 152.979 12.1905 152.032 11.945C150.839 11.6294 149.866 11.3138 149.112 10.9982C148.376 10.665 147.744 10.1565 147.218 9.47264C146.692 8.78879 146.429 7.87698 146.429 6.73722C146.429 5.68514 146.692 4.76457 147.218 3.97551C147.744 3.18644 148.481 2.5815 149.428 2.16066C150.375 1.73983 151.471 1.52941 152.716 1.52941C154.487 1.52941 155.933 1.97655 157.055 2.87082C158.195 3.74755 158.826 4.95745 158.949 6.50051H155.635C155.582 5.83419 155.267 5.26431 154.688 4.79087C154.11 4.31743 153.347 4.08071 152.4 4.08071C151.541 4.08071 150.839 4.2999 150.296 4.73827C149.752 5.17663 149.48 5.80788 149.48 6.63202C149.48 7.19313 149.647 7.6578 149.98 8.02603C150.331 8.37672 150.769 8.65728 151.295 8.86769C151.821 9.07811 152.54 9.30606 153.452 9.55155C154.662 9.88471 155.644 10.2179 156.398 10.551C157.169 10.8842 157.818 11.4015 158.344 12.1029C158.888 12.7867 159.16 13.7073 159.16 14.8646C159.16 15.7939 158.905 16.6706 158.397 17.4948C157.906 18.3189 157.178 18.9852 156.214 19.4937C155.267 19.9847 154.145 20.2302 152.847 20.2302Z"
        fill="#121212"
      />
      <path
        d="M168.616 7.77177C169.536 7.77177 170.354 7.96904 171.07 8.36357C171.801 8.7581 172.371 9.34259 172.78 10.117C173.204 10.8915 173.416 11.8267 173.416 12.9226V20.0461H170.939V13.2952C170.939 12.2139 170.669 11.3883 170.128 10.8184C169.587 10.2339 168.849 9.9417 167.914 9.9417C166.979 9.9417 166.234 10.2339 165.678 10.8184C165.138 11.3883 164.868 12.2139 164.868 13.2952V20.0461H162.369V3.82646H164.868V9.37182C165.291 8.86039 165.825 8.46586 166.468 8.18822C167.125 7.91059 167.841 7.77177 168.616 7.77177Z"
        fill="#121212"
      />
      <path
        d="M181.841 20.2433C180.702 20.2433 179.671 19.9876 178.751 19.4762C177.83 18.9502 177.107 18.2195 176.581 17.2844C176.055 16.3346 175.792 15.2386 175.792 13.9966C175.792 12.7692 176.062 11.6806 176.603 10.7308C177.144 9.78096 177.882 9.05035 178.817 8.53892C179.752 8.02749 180.797 7.77177 181.951 7.77177C183.105 7.77177 184.15 8.02749 185.085 8.53892C186.021 9.05035 186.758 9.78096 187.299 10.7308C187.84 11.6806 188.11 12.7692 188.11 13.9966C188.11 15.224 187.832 16.3126 187.277 17.2624C186.722 18.2122 185.962 18.9502 184.998 19.4762C184.048 19.9876 182.996 20.2433 181.841 20.2433ZM181.841 18.0734C182.484 18.0734 183.083 17.92 183.639 17.6131C184.209 17.3063 184.669 16.846 185.02 16.2323C185.37 15.6186 185.546 14.8733 185.546 13.9966C185.546 13.1199 185.378 12.3819 185.042 11.7828C184.705 11.1691 184.26 10.7088 183.704 10.402C183.149 10.0951 182.55 9.9417 181.907 9.9417C181.264 9.9417 180.665 10.0951 180.11 10.402C179.569 10.7088 179.138 11.1691 178.817 11.7828C178.495 12.3819 178.334 13.1199 178.334 13.9966C178.334 15.2971 178.663 16.3053 179.321 17.0213C179.993 17.7227 180.833 18.0734 181.841 18.0734Z"
        fill="#121212"
      />
      <path
        d="M193.079 9.74443C193.503 9.18916 194.08 8.72157 194.81 8.34165C195.541 7.96173 196.367 7.77177 197.287 7.77177C198.339 7.77177 199.296 8.03479 200.159 8.56084C201.035 9.07227 201.722 9.79557 202.219 10.7308C202.716 11.6659 202.964 12.7399 202.964 13.9528C202.964 15.1656 202.716 16.2542 202.219 17.2186C201.722 18.1684 201.035 18.9136 200.159 19.4543C199.296 19.9803 198.339 20.2433 197.287 20.2433C196.367 20.2433 195.548 20.0607 194.832 19.6954C194.116 19.3155 193.532 18.8479 193.079 18.2926V25.7887H190.58V7.96904H193.079V9.74443ZM200.422 13.9528C200.422 13.1199 200.246 12.4039 199.896 11.8048C199.559 11.191 199.106 10.7308 198.537 10.4239C197.981 10.1024 197.382 9.9417 196.739 9.9417C196.111 9.9417 195.512 10.1024 194.942 10.4239C194.387 10.7454 193.934 11.213 193.583 11.8267C193.247 12.4404 193.079 13.1637 193.079 13.9966C193.079 14.8295 193.247 15.5601 193.583 16.1884C193.934 16.8022 194.387 17.2698 194.942 17.5912C195.512 17.9127 196.111 18.0734 196.739 18.0734C197.382 18.0734 197.981 17.9127 198.537 17.5912C199.106 17.2551 199.559 16.7729 199.896 16.1446C200.246 15.5163 200.422 14.7857 200.422 13.9528Z"
        fill="#121212"
      />
      <path
        d="M207.934 9.74443C208.358 9.18916 208.935 8.72157 209.665 8.34165C210.396 7.96173 211.222 7.77177 212.142 7.77177C213.194 7.77177 214.151 8.03479 215.013 8.56084C215.89 9.07227 216.577 9.79557 217.074 10.7308C217.571 11.6659 217.819 12.7399 217.819 13.9528C217.819 15.1656 217.571 16.2542 217.074 17.2186C216.577 18.1684 215.89 18.9136 215.013 19.4543C214.151 19.9803 213.194 20.2433 212.142 20.2433C211.222 20.2433 210.403 20.0607 209.687 19.6954C208.971 19.3155 208.387 18.8479 207.934 18.2926V25.7887H205.435V7.96904H207.934V9.74443ZM215.276 13.9528C215.276 13.1199 215.101 12.4039 214.75 11.8048C214.414 11.191 213.961 10.7308 213.391 10.4239C212.836 10.1024 212.237 9.9417 211.594 9.9417C210.966 9.9417 210.367 10.1024 209.797 10.4239C209.242 10.7454 208.789 11.213 208.438 11.8267C208.102 12.4404 207.934 13.1637 207.934 13.9966C207.934 14.8295 208.102 15.5601 208.438 16.1884C208.789 16.8022 209.242 17.2698 209.797 17.5912C210.367 17.9127 210.966 18.0734 211.594 18.0734C212.237 18.0734 212.836 17.9127 213.391 17.5912C213.961 17.2551 214.414 16.7729 214.75 16.1446C215.101 15.5163 215.276 14.7857 215.276 13.9528Z"
        fill="#121212"
      />
      <path
        d="M231.359 13.7117C231.359 14.1646 231.329 14.5738 231.271 14.9391H222.043C222.116 15.9035 222.474 16.678 223.117 17.2624C223.76 17.8469 224.549 18.1392 225.485 18.1392C226.829 18.1392 227.779 17.5766 228.334 16.4515H231.03C230.665 17.562 230 18.4753 229.035 19.1913C228.086 19.8927 226.902 20.2433 225.485 20.2433C224.33 20.2433 223.293 19.9876 222.372 19.4762C221.466 18.9502 220.75 18.2195 220.224 17.2844C219.713 16.3346 219.457 15.2386 219.457 13.9966C219.457 12.7546 219.705 11.6659 220.202 10.7308C220.714 9.78096 221.422 9.05035 222.328 8.53892C223.249 8.02749 224.301 7.77177 225.485 7.77177C226.624 7.77177 227.64 8.02018 228.531 8.517C229.423 9.01382 230.117 9.71521 230.613 10.6212C231.11 11.5125 231.359 12.5427 231.359 13.7117ZM228.75 12.9226C228.736 12.002 228.407 11.2641 227.764 10.7088C227.121 10.1536 226.325 9.87594 225.375 9.87594C224.513 9.87594 223.775 10.1536 223.161 10.7088C222.548 11.2495 222.182 11.9874 222.065 12.9226H228.75Z"
        fill="#121212"
      />
      <path
        d="M236.316 9.72251C236.682 9.1088 237.164 8.6339 237.763 8.29782C238.377 7.94712 239.1 7.77177 239.933 7.77177V10.3581H239.297C238.318 10.3581 237.573 10.6066 237.062 11.1034C236.565 11.6002 236.316 12.4623 236.316 13.6897V20.0461H233.818V7.96904H236.316V9.72251Z"
        fill="#121212"
      />
      <path
        d="M91.9086 56.5065C89.9418 56.5065 88.1716 56.1693 86.5981 55.495C85.0247 54.7925 83.7883 53.8091 82.8892 52.5447C81.9901 51.2803 81.5405 49.8051 81.5405 48.1193H86.6824C86.7948 49.3837 87.2865 50.4233 88.1575 51.2381C89.0567 52.053 90.307 52.4604 91.9086 52.4604C93.5664 52.4604 94.8589 52.067 95.7861 51.2803C96.7133 50.4654 97.177 49.4258 97.177 48.1614C97.177 47.178 96.8819 46.3772 96.2919 45.7591C95.7299 45.1409 95.0134 44.6632 94.1424 44.3261C93.2995 43.9889 92.1193 43.6236 90.6021 43.2303C88.6914 42.7245 87.132 42.2187 85.9238 41.713C84.7437 41.1791 83.7321 40.3643 82.8892 39.2685C82.0463 38.1727 81.6248 36.7116 81.6248 34.8852C81.6248 33.1993 82.0463 31.7242 82.8892 30.4598C83.7321 29.1954 84.9123 28.226 86.4295 27.5517C87.9468 26.8773 89.7029 26.5402 91.6979 26.5402C94.5358 26.5402 96.8538 27.2567 98.6521 28.6896C100.478 30.0945 101.49 32.0333 101.687 34.5059H96.3762C96.2919 33.4382 95.7861 32.525 94.8589 31.7663C93.9317 31.0077 92.7094 30.6284 91.1921 30.6284C89.8153 30.6284 88.6914 30.9796 87.8204 31.6821C86.9493 32.3845 86.5138 33.396 86.5138 34.7166C86.5138 35.6158 86.7808 36.3603 87.3146 36.9504C87.8766 37.5124 88.579 37.9619 89.422 38.2991C90.2649 38.6363 91.4169 39.0015 92.878 39.3949C94.8167 39.9288 96.3902 40.4626 97.5984 40.9965C98.8347 41.5303 99.8743 42.3592 100.717 43.4831C101.588 44.579 102.024 46.0541 102.024 47.9085C102.024 49.3977 101.616 50.8026 100.802 52.1232C100.015 53.4438 98.8488 54.5115 97.3034 55.3264C95.7861 56.1131 93.9879 56.5065 91.9086 56.5065Z"
        fill="#121212"
      />
      <path
        d="M105.832 46.4475C105.832 44.504 106.23 42.783 107.026 41.2845C107.845 39.7859 108.946 38.6269 110.327 37.8074C111.732 36.9645 113.278 36.543 114.964 36.543C116.485 36.543 117.808 36.8474 118.932 37.4562C120.08 38.0415 120.993 38.7791 121.672 39.6689V36.8591H125.711V56.2114H121.672V53.3314C120.993 54.2446 120.068 55.0056 118.897 55.6144C117.726 56.2232 116.392 56.5276 114.893 56.5276C113.231 56.5276 111.709 56.1061 110.327 55.2632C108.946 54.3968 107.845 53.2026 107.026 51.6807C106.23 50.1353 105.832 48.3909 105.832 46.4475ZM121.672 46.5177C121.672 45.1831 121.391 44.024 120.829 43.0406C120.29 42.0572 119.576 41.3079 118.686 40.7928C117.797 40.2776 116.837 40.0201 115.806 40.0201C114.776 40.0201 113.816 40.2776 112.926 40.7928C112.037 41.2845 111.311 42.0221 110.749 43.0055C110.21 43.9655 109.941 45.1128 109.941 46.4475C109.941 47.7821 110.21 48.9528 110.749 49.9597C111.311 50.9665 112.037 51.7392 112.926 52.2778C113.84 52.7929 114.8 53.0504 115.806 53.0504C116.837 53.0504 117.797 52.7929 118.686 52.2778C119.576 51.7626 120.29 51.0134 120.829 50.0299C121.391 49.0231 121.672 47.8524 121.672 46.5177Z"
        fill="#121212"
      />
      <path
        d="M138.451 52.629L143.93 36.8591H148.18L140.804 56.2114H136.028L128.687 36.8591H132.972L138.451 52.629Z"
        fill="#121212"
      />
      <path
        d="M153.243 34.2952C152.518 34.2952 151.909 34.0493 151.417 33.5576C150.925 33.0659 150.68 32.4571 150.68 31.7312C150.68 31.0054 150.925 30.3966 151.417 29.9049C151.909 29.4132 152.518 29.1673 153.243 29.1673C153.946 29.1673 154.543 29.4132 155.035 29.9049C155.526 30.3966 155.772 31.0054 155.772 31.7312C155.772 32.4571 155.526 33.0659 155.035 33.5576C154.543 34.0493 153.946 34.2952 153.243 34.2952ZM155.21 36.8591V56.2114H151.206V36.8591H155.21Z"
        fill="#121212"
      />
      <path
        d="M170.231 36.543C171.753 36.543 173.111 36.8591 174.305 37.4913C175.523 38.1235 176.471 39.0601 177.15 40.3011C177.829 41.542 178.169 43.0406 178.169 44.7967V56.2114H174.2V45.3938C174.2 43.6611 173.767 42.3382 172.9 41.425C172.034 40.4884 170.852 40.0201 169.353 40.0201C167.855 40.0201 166.66 40.4884 165.771 41.425C164.904 42.3382 164.471 43.6611 164.471 45.3938V56.2114H160.467V36.8591H164.471V39.0718C165.127 38.2757 165.958 37.6552 166.965 37.2103C167.995 36.7654 169.084 36.543 170.231 36.543Z"
        fill="#121212"
      />
      <path
        d="M191.073 36.543C192.572 36.543 193.895 36.8474 195.042 37.4562C196.213 38.0415 197.126 38.7791 197.782 39.6689V36.8591H201.821V56.5276C201.821 58.3071 201.446 59.8876 200.697 61.2691C199.947 62.6739 198.859 63.7744 197.43 64.5705C196.025 65.3667 194.34 65.7647 192.373 65.7647C189.75 65.7647 187.573 65.1442 185.84 63.9032C184.107 62.6856 183.124 61.0232 182.89 58.9159H186.859C187.163 59.9227 187.807 60.7305 188.79 61.3393C189.797 61.9715 190.991 62.2876 192.373 62.2876C193.988 62.2876 195.288 61.7959 196.271 60.8125C197.278 59.829 197.782 58.4007 197.782 56.5276V53.2963C197.102 54.2095 196.178 54.9822 195.007 55.6144C193.86 56.2232 192.548 56.5276 191.073 56.5276C189.387 56.5276 187.842 56.1061 186.437 55.2632C185.056 54.3968 183.955 53.2026 183.136 51.6807C182.339 50.1353 181.941 48.3909 181.941 46.4475C181.941 44.504 182.339 42.783 183.136 41.2845C183.955 39.7859 185.056 38.6269 186.437 37.8074C187.842 36.9645 189.387 36.543 191.073 36.543ZM197.782 46.5177C197.782 45.1831 197.501 44.024 196.939 43.0406C196.4 42.0572 195.686 41.3079 194.796 40.7928C193.906 40.2776 192.946 40.0201 191.916 40.0201C190.886 40.0201 189.926 40.2776 189.036 40.7928C188.146 41.2845 187.42 42.0221 186.859 43.0055C186.32 43.9655 186.051 45.1128 186.051 46.4475C186.051 47.7821 186.32 48.9528 186.859 49.9597C187.42 50.9665 188.146 51.7392 189.036 52.2778C189.949 52.7929 190.909 53.0504 191.916 53.0504C192.946 53.0504 193.906 52.7929 194.796 52.2778C195.686 51.7626 196.4 51.0134 196.939 50.0299C197.501 49.0231 197.782 47.8524 197.782 46.5177Z"
        fill="#121212"
      />
      <path
        d="M214.104 56.5276C212.582 56.5276 211.212 56.2583 209.995 55.7197C208.801 55.1578 207.852 54.4085 207.15 53.4719C206.447 52.5119 206.073 51.4465 206.026 50.2758H210.17C210.241 51.0953 210.627 51.786 211.329 52.348C212.055 52.8865 212.957 53.1558 214.034 53.1558C215.158 53.1558 216.024 52.9451 216.633 52.5236C217.265 52.0787 217.581 51.5168 217.581 50.8377C217.581 50.1119 217.23 49.5733 216.527 49.2221C215.848 48.8709 214.76 48.4846 213.261 48.0631C211.809 47.665 210.627 47.2787 209.714 46.904C208.801 46.5294 208.004 45.9557 207.325 45.1831C206.67 44.4104 206.342 43.3918 206.342 42.1274C206.342 41.0972 206.646 40.1606 207.255 39.3176C207.864 38.4513 208.73 37.7723 209.854 37.2806C211.002 36.7888 212.313 36.543 213.788 36.543C215.989 36.543 217.757 37.1049 219.091 38.2289C220.449 39.3293 221.175 40.8396 221.269 42.7596H217.265C217.195 41.8933 216.844 41.2025 216.211 40.6874C215.579 40.1723 214.725 39.9147 213.647 39.9147C212.594 39.9147 211.786 40.1137 211.224 40.5118C210.662 40.9098 210.381 41.4367 210.381 42.0923C210.381 42.6074 210.568 43.0406 210.943 43.3918C211.318 43.743 211.774 44.024 212.313 44.2348C212.851 44.4221 213.647 44.6679 214.701 44.9723C216.106 45.347 217.253 45.7333 218.143 46.1314C219.056 46.506 219.841 47.068 220.496 47.8172C221.152 48.5665 221.491 49.5616 221.515 50.8026C221.515 51.9031 221.21 52.8865 220.602 53.7529C219.993 54.6192 219.127 55.2983 218.003 55.79C216.902 56.2817 215.603 56.5276 214.104 56.5276Z"
        fill="#121212"
      />
    </svg>
  );
}

export default Logo;
