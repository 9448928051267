import React from "react";

function MobileLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="174"
      height="48"
      viewBox="0 0 174 48"
      fill="none"
    >
      <path
        d="M9.67634 10.8365C8.60224 11.9094 7.99882 13.3645 7.99882 14.8818C7.99882 16.399 8.60224 17.8542 9.67634 18.927C10.7504 19.9999 12.2072 20.6026 13.7262 20.6026C15.2452 20.6026 16.702 19.9999 17.7761 18.927C18.8502 17.8542 19.4537 16.399 19.4537 14.8818C19.4537 13.3645 18.8502 11.9094 17.7761 10.8365C16.702 9.76369 15.2452 9.16096 13.7262 9.16096C12.2072 9.16096 10.7504 9.76369 9.67634 10.8365Z"
        fill="#121212"
      />
      <path
        d="M1.43596 4.51363C1.50261 3.64796 2.19123 2.96012 3.0579 2.89354L20.0278 1.58979C20.5408 1.55038 21.0453 1.73682 21.4091 2.10017L39.6579 20.3279C40.3461 21.0153 40.3461 22.1298 39.6579 22.8173L21.3809 41.0732C20.6927 41.7606 19.5768 41.7606 18.8886 41.0731L0.641597 22.8455C0.277866 22.4821 0.0912368 21.9782 0.130683 21.4659L1.43596 4.51363ZM9.75009 18.9817C10.26 19.4993 10.8675 19.9109 11.5374 20.1928C12.2074 20.4747 12.9266 20.6213 13.6536 20.6242C14.3806 20.627 15.1009 20.4861 15.7731 20.2095C16.4453 19.9329 17.056 19.5261 17.5699 19.0126C18.0839 18.4991 18.4911 17.889 18.7678 17.2175C19.0446 16.546 19.1855 15.8265 19.1824 15.1004C19.1794 14.3742 19.0324 13.6559 18.7501 12.9867C18.4677 12.3176 18.0555 11.711 17.5372 11.2018C16.5014 10.1842 15.1051 9.61658 13.6523 9.62266C12.1995 9.62874 10.8079 10.208 9.78075 11.2342C8.75358 12.2604 8.174 13.6505 8.16825 15.1017C8.16251 16.5528 8.73107 17.9474 9.75009 18.9817Z"
        fill="#FF4F4B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.1812 0.386675C26.6974 -0.128892 27.5342 -0.128892 28.0504 0.386675L48.3167 20.6296C48.8328 21.1452 48.8328 21.9811 48.3167 22.4966L30.6938 40.0991C30.1777 40.6147 29.3408 40.6147 28.8246 40.0991C28.3085 39.5836 28.3085 38.7477 28.8246 38.2321L45.5129 21.5631L26.1812 2.2537C25.665 1.73814 25.665 0.902241 26.1812 0.386675Z"
        fill="#121212"
      />
      <path
        d="M68.383 5.08974C68.0401 4.42927 67.5638 3.93391 66.9541 3.60368C66.3444 3.26074 65.6395 3.08927 64.8393 3.08927C63.9629 3.08927 63.1818 3.28614 62.4959 3.67988C61.81 4.07363 61.2702 4.63249 60.8765 5.35647C60.4954 6.08045 60.3049 6.91875 60.3049 7.87136C60.3049 8.82397 60.4954 9.66861 60.8765 10.4053C61.2702 11.1293 61.81 11.6881 62.4959 12.0819C63.1818 12.4756 63.9629 12.6725 64.8393 12.6725C66.0205 12.6725 66.9795 12.3423 67.7162 11.6818C68.4529 11.0213 68.9038 10.1259 69.0689 8.99543H64.0963V7.26169H71.3932V8.95733C71.2535 9.98615 70.8852 10.9324 70.2882 11.7961C69.7039 12.6598 68.9355 13.352 67.9829 13.8728C67.043 14.3808 65.9951 14.6349 64.8393 14.6349C63.5946 14.6349 62.4578 14.3491 61.429 13.7775C60.4001 13.1933 59.5809 12.3867 58.9712 11.3579C58.3743 10.3291 58.0758 9.1669 58.0758 7.87136C58.0758 6.57581 58.3743 5.41363 58.9712 4.38481C59.5809 3.356 60.4001 2.55581 61.429 1.98424C62.4705 1.39998 63.6073 1.10784 64.8393 1.10784C66.2492 1.10784 67.5003 1.45713 68.5926 2.15571C69.6976 2.84159 70.4978 3.8196 70.9931 5.08974H68.383Z"
        fill="#121212"
      />
      <path
        d="M75.1072 7.04259C75.3718 6.59804 75.7211 6.25404 76.1551 6.0106C76.5996 5.75657 77.1236 5.62955 77.7269 5.62955V7.50302H77.2665C76.5573 7.50302 76.0175 7.68295 75.647 8.04283C75.2872 8.4027 75.1072 9.02719 75.1072 9.91629V14.5206H73.2973V5.77245H75.1072V7.04259Z"
        fill="#121212"
      />
      <path
        d="M87.4084 9.93217C87.4084 10.2603 87.3872 10.5567 87.3449 10.8213H80.6607C80.7137 11.5198 80.973 12.0808 81.4387 12.5042C81.9044 12.9276 82.476 13.1393 83.1534 13.1393C84.1272 13.1393 84.8152 12.7318 85.2174 11.9168H87.1702C86.9056 12.7212 86.424 13.3827 85.7254 13.9014C85.0374 14.4094 84.1801 14.6635 83.1534 14.6635C82.3172 14.6635 81.5657 14.4782 80.8989 14.1078C80.2427 13.7267 79.724 13.1975 79.343 12.5201C78.9725 11.8321 78.7873 11.0382 78.7873 10.1386C78.7873 9.23888 78.9672 8.45033 79.3271 7.77292C79.6976 7.08493 80.2109 6.5557 80.8671 6.18524C81.534 5.81478 82.2961 5.62955 83.1534 5.62955C83.979 5.62955 84.7146 5.80949 85.3603 6.16937C86.0059 6.52924 86.5087 7.0373 86.8686 7.69354C87.2284 8.33919 87.4084 9.0854 87.4084 9.93217ZM85.519 9.3606C85.5085 8.69378 85.2703 8.15926 84.8046 7.75704C84.3389 7.35483 83.762 7.15373 83.074 7.15373C82.4495 7.15373 81.915 7.35483 81.4705 7.75704C81.0259 8.14867 80.7613 8.68319 80.6766 9.3606H85.519Z"
        fill="#121212"
      />
      <path
        d="M88.5862 10.1068C88.5862 9.22829 88.7662 8.45033 89.1261 7.77292C89.4965 7.09551 89.994 6.57158 90.6185 6.20112C91.2535 5.82008 91.9521 5.62955 92.7142 5.62955C93.4022 5.62955 94.0002 5.76715 94.5083 6.04235C95.0269 6.30696 95.4397 6.64038 95.7467 7.04259V5.77245H97.5725V14.5206H95.7467V13.2187C95.4397 13.6315 95.0216 13.9755 94.4924 14.2507C93.9632 14.5259 93.3599 14.6635 92.6825 14.6635C91.931 14.6635 91.243 14.4729 90.6185 14.0919C89.994 13.7003 89.4965 13.1604 89.1261 12.4725C88.7662 11.7739 88.5862 10.9853 88.5862 10.1068ZM95.7467 10.1386C95.7467 9.53525 95.6197 9.01131 95.3656 8.56676C95.1222 8.12221 94.7994 7.78351 94.3971 7.55065C93.9949 7.31779 93.561 7.20136 93.0953 7.20136C92.6295 7.20136 92.1956 7.31779 91.7934 7.55065C91.3911 7.77292 91.063 8.10633 90.809 8.55088C90.5655 8.98485 90.4438 9.50349 90.4438 10.1068C90.4438 10.7101 90.5655 11.2394 90.809 11.6945C91.063 12.1496 91.3911 12.4989 91.7934 12.7424C92.2062 12.9752 92.6401 13.0916 93.0953 13.0916C93.561 13.0916 93.9949 12.9752 94.3971 12.7424C94.7994 12.5095 95.1222 12.1708 95.3656 11.7262C95.6197 11.2711 95.7467 10.7419 95.7467 10.1386Z"
        fill="#121212"
      />
      <path
        d="M102.061 7.24899V12.0914C102.061 12.4195 102.136 12.6577 102.284 12.8059C102.442 12.9435 102.707 13.0123 103.078 13.0123H104.189V14.5206H102.76C101.945 14.5206 101.321 14.33 100.887 13.949C100.453 13.568 100.236 12.9488 100.236 12.0914V7.24899H99.2036V5.77245H100.236V3.59732H102.061V5.77245H104.189V7.24899H102.061Z"
        fill="#121212"
      />
      <path
        d="M110.793 14.6539C109.904 14.6539 109.104 14.5015 108.393 14.1967C107.681 13.8791 107.122 13.4346 106.716 12.863C106.309 12.2915 106.106 11.6246 106.106 10.8625H108.431C108.481 11.4341 108.704 11.9041 109.097 12.2724C109.504 12.6407 110.069 12.8249 110.793 12.8249C111.542 12.8249 112.127 12.6471 112.546 12.2915C112.965 11.9231 113.175 11.4532 113.175 10.8816C113.175 10.437 113.041 10.0751 112.775 9.79563C112.52 9.51619 112.197 9.30027 111.803 9.14785C111.422 8.99543 110.888 8.83032 110.202 8.6525C109.339 8.42387 108.634 8.19524 108.088 7.96662C107.554 7.72529 107.097 7.35695 106.716 6.86159C106.335 6.36624 106.144 5.70576 106.144 4.88017C106.144 4.11808 106.335 3.45126 106.716 2.87969C107.097 2.30813 107.63 1.86993 108.316 1.5651C109.002 1.26026 109.796 1.10784 110.698 1.10784C111.981 1.10784 113.029 1.43173 113.841 2.0795C114.667 2.71457 115.124 3.59097 115.213 4.7087H112.813C112.775 4.22605 112.546 3.81325 112.127 3.47031C111.708 3.12737 111.155 2.9559 110.469 2.9559C109.847 2.9559 109.339 3.11467 108.945 3.43221C108.551 3.74974 108.354 4.20699 108.354 4.80396C108.354 5.21041 108.475 5.547 108.716 5.81373C108.97 6.06775 109.288 6.27098 109.669 6.42339C110.05 6.57581 110.571 6.74093 111.231 6.91875C112.108 7.16008 112.819 7.4014 113.365 7.64273C113.924 7.88406 114.394 8.25875 114.775 8.76681C115.169 9.26216 115.366 9.92899 115.366 10.7673C115.366 11.4405 115.181 12.0755 114.813 12.6725C114.457 13.2695 113.93 13.7521 113.232 14.1205C112.546 14.4761 111.733 14.6539 110.793 14.6539Z"
        fill="#121212"
      />
      <path
        d="M122.215 5.62955C122.882 5.62955 123.475 5.77245 123.993 6.05823C124.523 6.34401 124.935 6.76739 125.232 7.32837C125.539 7.88935 125.692 8.56676 125.692 9.3606V14.5206H123.898V9.63051C123.898 8.84725 123.702 8.24923 123.311 7.83643C122.919 7.41305 122.385 7.20136 121.707 7.20136C121.03 7.20136 120.49 7.41305 120.088 7.83643C119.696 8.24923 119.5 8.84725 119.5 9.63051V14.5206H117.69V2.77173H119.5V6.78856C119.807 6.4181 120.193 6.13232 120.659 5.93121C121.136 5.73011 121.654 5.62955 122.215 5.62955Z"
        fill="#121212"
      />
      <path
        d="M131.795 14.6635C130.97 14.6635 130.224 14.4782 129.557 14.1078C128.89 13.7267 128.366 13.1975 127.985 12.5201C127.604 11.8321 127.413 11.0382 127.413 10.1386C127.413 9.24946 127.609 8.46092 128.001 7.77292C128.393 7.08493 128.927 6.5557 129.604 6.18524C130.282 5.81478 131.039 5.62955 131.875 5.62955C132.711 5.62955 133.468 5.81478 134.145 6.18524C134.823 6.5557 135.357 7.08493 135.749 7.77292C136.14 8.46092 136.336 9.24946 136.336 10.1386C136.336 11.0277 136.135 11.8162 135.733 12.5042C135.331 13.1922 134.78 13.7267 134.082 14.1078C133.394 14.4782 132.632 14.6635 131.795 14.6635ZM131.795 13.0916C132.261 13.0916 132.695 12.9805 133.097 12.7582C133.51 12.536 133.844 12.2025 134.098 11.758C134.352 11.3134 134.479 10.7736 134.479 10.1386C134.479 9.50349 134.357 8.96897 134.113 8.53501C133.87 8.09046 133.547 7.75704 133.145 7.53477C132.743 7.31249 132.309 7.20136 131.843 7.20136C131.377 7.20136 130.943 7.31249 130.541 7.53477C130.15 7.75704 129.837 8.09046 129.604 8.53501C129.372 8.96897 129.255 9.50349 129.255 10.1386C129.255 11.0806 129.493 11.8109 129.97 12.3296C130.456 12.8376 131.065 13.0916 131.795 13.0916Z"
        fill="#121212"
      />
      <path
        d="M139.935 7.05847C140.242 6.65625 140.66 6.31755 141.19 6.04235C141.719 5.76715 142.317 5.62955 142.984 5.62955C143.746 5.62955 144.439 5.82008 145.064 6.20112C145.699 6.57158 146.196 7.09551 146.556 7.77292C146.916 8.45033 147.096 9.22829 147.096 10.1068C147.096 10.9853 146.916 11.7739 146.556 12.4725C146.196 13.1604 145.699 13.7003 145.064 14.0919C144.439 14.4729 143.746 14.6635 142.984 14.6635C142.317 14.6635 141.724 14.5311 141.206 14.2665C140.687 13.9913 140.264 13.6526 139.935 13.2504V18.6803H138.125V5.77245H139.935V7.05847ZM145.254 10.1068C145.254 9.50349 145.127 8.98485 144.873 8.55088C144.63 8.10633 144.302 7.77292 143.889 7.55065C143.486 7.31779 143.053 7.20136 142.587 7.20136C142.132 7.20136 141.698 7.31779 141.285 7.55065C140.883 7.78351 140.555 8.12221 140.301 8.56676C140.057 9.01131 139.935 9.53525 139.935 10.1386C139.935 10.7419 140.057 11.2711 140.301 11.7262C140.555 12.1708 140.883 12.5095 141.285 12.7424C141.698 12.9752 142.132 13.0916 142.587 13.0916C143.053 13.0916 143.486 12.9752 143.889 12.7424C144.302 12.4989 144.63 12.1496 144.873 11.6945C145.127 11.2394 145.254 10.7101 145.254 10.1068Z"
        fill="#121212"
      />
      <path
        d="M150.696 7.05847C151.003 6.65625 151.421 6.31755 151.95 6.04235C152.479 5.76715 153.077 5.62955 153.744 5.62955C154.506 5.62955 155.199 5.82008 155.824 6.20112C156.459 6.57158 156.956 7.09551 157.316 7.77292C157.676 8.45033 157.856 9.22829 157.856 10.1068C157.856 10.9853 157.676 11.7739 157.316 12.4725C156.956 13.1604 156.459 13.7003 155.824 14.0919C155.199 14.4729 154.506 14.6635 153.744 14.6635C153.077 14.6635 152.484 14.5311 151.966 14.2665C151.447 13.9913 151.024 13.6526 150.696 13.2504V18.6803H148.886V5.77245H150.696V7.05847ZM156.014 10.1068C156.014 9.50349 155.887 8.98485 155.633 8.55088C155.39 8.10633 155.062 7.77292 154.649 7.55065C154.247 7.31779 153.813 7.20136 153.347 7.20136C152.892 7.20136 152.458 7.31779 152.045 7.55065C151.643 7.78351 151.315 8.12221 151.061 8.56676C150.817 9.01131 150.696 9.53525 150.696 10.1386C150.696 10.7419 150.817 11.2711 151.061 11.7262C151.315 12.1708 151.643 12.5095 152.045 12.7424C152.458 12.9752 152.892 13.0916 153.347 13.0916C153.813 13.0916 154.247 12.9752 154.649 12.7424C155.062 12.4989 155.39 12.1496 155.633 11.6945C155.887 11.2394 156.014 10.7101 156.014 10.1068Z"
        fill="#121212"
      />
      <path
        d="M167.664 9.93217C167.664 10.2603 167.643 10.5567 167.6 10.8213H160.916C160.969 11.5198 161.228 12.0808 161.694 12.5042C162.16 12.9276 162.731 13.1393 163.409 13.1393C164.383 13.1393 165.071 12.7318 165.473 11.9168H167.426C167.161 12.7212 166.679 13.3827 165.981 13.9014C165.293 14.4094 164.435 14.6635 163.409 14.6635C162.573 14.6635 161.821 14.4782 161.154 14.1078C160.498 13.7267 159.979 13.1975 159.598 12.5201C159.228 11.8321 159.043 11.0382 159.043 10.1386C159.043 9.23888 159.223 8.45033 159.582 7.77292C159.953 7.08493 160.466 6.5557 161.122 6.18524C161.789 5.81478 162.551 5.62955 163.409 5.62955C164.234 5.62955 164.97 5.80949 165.616 6.16937C166.261 6.52924 166.764 7.0373 167.124 7.69354C167.484 8.33919 167.664 9.0854 167.664 9.93217ZM165.774 9.3606C165.764 8.69378 165.526 8.15926 165.06 7.75704C164.594 7.35483 164.017 7.15373 163.329 7.15373C162.705 7.15373 162.17 7.35483 161.726 7.75704C161.281 8.14867 161.017 8.68319 160.932 9.3606H165.774Z"
        fill="#121212"
      />
      <path
        d="M171.255 7.04259C171.519 6.59804 171.869 6.25404 172.303 6.0106C172.747 5.75657 173.271 5.62955 173.875 5.62955V7.50302H173.414C172.705 7.50302 172.165 7.68295 171.795 8.04283C171.435 8.4027 171.255 9.02719 171.255 9.91629V14.5206H169.445V5.77245H171.255V7.04259Z"
        fill="#121212"
      />
      <path
        d="M66.6518 40.931C65.2271 40.931 63.9448 40.6867 62.8051 40.1983C61.6653 39.6894 60.7698 38.9771 60.1185 38.0612C59.4672 37.1453 59.1416 36.0768 59.1416 34.8556H62.8661C62.9476 35.7715 63.3037 36.5246 63.9347 37.1148C64.586 37.705 65.4917 38.0002 66.6518 38.0002C67.8526 38.0002 68.7888 37.7152 69.4605 37.1453C70.1321 36.5551 70.468 35.802 70.468 34.8862C70.468 34.1738 70.2542 33.5938 69.8268 33.146C69.4198 32.6982 68.9008 32.3522 68.2698 32.108C67.6592 31.8638 66.8044 31.5992 65.7054 31.3142C64.3214 30.9479 63.1918 30.5815 62.3166 30.2152C61.4618 29.8285 60.7291 29.2382 60.1185 28.4445C59.5079 27.6507 59.2026 26.5924 59.2026 25.2694C59.2026 24.0482 59.5079 22.9797 60.1185 22.0638C60.7291 21.148 61.5839 20.4458 62.683 19.9573C63.782 19.4688 65.0541 19.2246 66.4991 19.2246C68.5548 19.2246 70.2339 19.7436 71.5365 20.7816C72.8594 21.7992 73.5921 23.2036 73.7346 24.9947H69.8879C69.8268 24.2212 69.4605 23.5598 68.7888 23.0102C68.1172 22.4607 67.2318 22.1859 66.1328 22.1859C65.1355 22.1859 64.3214 22.4404 63.6904 22.9492C63.0595 23.458 62.744 24.1907 62.744 25.1473C62.744 25.7986 62.9374 26.3379 63.3241 26.7654C63.7311 27.1724 64.24 27.4981 64.8505 27.7423C65.4611 27.9865 66.2956 28.2511 67.354 28.5361C68.7583 28.9228 69.8981 29.3095 70.7732 29.6962C71.6688 30.0829 72.4218 30.6833 73.0324 31.4974C73.6633 32.2912 73.9788 33.3597 73.9788 34.703C73.9788 35.7817 73.6837 36.7993 73.0935 37.7559C72.5236 38.7125 71.6789 39.4859 70.5595 40.0762C69.4605 40.646 68.1579 40.931 66.6518 40.931Z"
        fill="#121212"
      />
      <path
        d="M76.7371 33.6446C76.7371 32.2369 77.0255 30.9903 77.6021 29.9048C78.1957 28.8193 78.9929 27.9797 79.9936 27.3861C81.0112 26.7755 82.1306 26.4702 83.3518 26.4702C84.4543 26.4702 85.4125 26.6907 86.2267 27.1317C87.0577 27.5557 87.7192 28.09 88.2111 28.7345V26.6992H91.1368V40.7173H88.2111V38.6311C87.7192 39.2926 87.0493 39.8438 86.2012 40.2848C85.3532 40.7257 84.3864 40.9462 83.3009 40.9462C82.0967 40.9462 80.9943 40.6409 79.9936 40.0304C78.9929 39.4028 78.1957 38.5378 77.6021 37.4354C77.0255 36.316 76.7371 35.0524 76.7371 33.6446ZM88.2111 33.6955C88.2111 32.7288 88.0075 31.8892 87.6005 31.1768C87.2104 30.4645 86.6931 29.9217 86.0486 29.5486C85.4041 29.1755 84.7087 28.9889 83.9624 28.9889C83.2161 28.9889 82.5207 29.1755 81.8762 29.5486C81.2317 29.9048 80.7059 30.4391 80.2989 31.1514C79.9088 31.8468 79.7137 32.6779 79.7137 33.6446C79.7137 34.6114 79.9088 35.4594 80.2989 36.1887C80.7059 36.9181 81.2317 37.4778 81.8762 37.8679C82.5377 38.241 83.2331 38.4276 83.9624 38.4276C84.7087 38.4276 85.4041 38.241 86.0486 37.8679C86.6931 37.4947 87.2104 36.952 87.6005 36.2396C88.0075 35.5103 88.2111 34.6623 88.2111 33.6955Z"
        fill="#121212"
      />
      <path
        d="M100.365 38.1223L104.334 26.6992H107.412L102.07 40.7173H98.6097L93.2925 26.6992H96.3964L100.365 38.1223Z"
        fill="#121212"
      />
      <path
        d="M111.08 24.842C110.554 24.842 110.113 24.6639 109.757 24.3077C109.401 23.9516 109.223 23.5106 109.223 22.9848C109.223 22.459 109.401 22.018 109.757 21.6619C110.113 21.3057 110.554 21.1276 111.08 21.1276C111.589 21.1276 112.022 21.3057 112.378 21.6619C112.734 22.018 112.912 22.459 112.912 22.9848C112.912 23.5106 112.734 23.9516 112.378 24.3077C112.022 24.6639 111.589 24.842 111.08 24.842ZM112.505 26.6992V40.7173H109.605V26.6992H112.505Z"
        fill="#121212"
      />
      <path
        d="M123.385 26.4702C124.488 26.4702 125.472 26.6992 126.337 27.1571C127.219 27.6151 127.905 28.2935 128.397 29.1924C128.889 30.0914 129.135 31.1768 129.135 32.4489V40.7173H126.26V32.8814C126.26 31.6263 125.946 30.668 125.319 30.0066C124.691 29.3281 123.835 28.9889 122.749 28.9889C121.664 28.9889 120.799 29.3281 120.154 30.0066C119.527 30.668 119.213 31.6263 119.213 32.8814V40.7173H116.313V26.6992H119.213V28.302C119.688 27.7253 120.29 27.2759 121.019 26.9536C121.766 26.6314 122.554 26.4702 123.385 26.4702Z"
        fill="#121212"
      />
      <path
        d="M138.483 26.4702C139.568 26.4702 140.526 26.6907 141.357 27.1317C142.205 27.5557 142.867 28.09 143.342 28.7345V26.6992H146.268V40.9462C146.268 42.2353 145.996 43.3801 145.453 44.3808C144.911 45.3984 144.122 46.1956 143.087 46.7723C142.07 47.3489 140.849 47.6373 139.424 47.6373C137.524 47.6373 135.947 47.1878 134.692 46.2889C133.437 45.4069 132.724 44.2027 132.555 42.6762H135.43C135.65 43.4055 136.117 43.9907 136.829 44.4317C137.558 44.8896 138.423 45.1186 139.424 45.1186C140.594 45.1186 141.535 44.7624 142.248 44.0501C142.977 43.3377 143.342 42.3031 143.342 40.9462V38.6057C142.85 39.2671 142.18 39.8268 141.332 40.2848C140.501 40.7257 139.551 40.9462 138.483 40.9462C137.261 40.9462 136.142 40.6409 135.124 40.0304C134.124 39.4028 133.326 38.5378 132.733 37.4354C132.156 36.316 131.868 35.0524 131.868 33.6446C131.868 32.2369 132.156 30.9903 132.733 29.9048C133.326 28.8193 134.124 27.9797 135.124 27.3861C136.142 26.7755 137.261 26.4702 138.483 26.4702ZM143.342 33.6955C143.342 32.7288 143.138 31.8892 142.731 31.1768C142.341 30.4645 141.824 29.9217 141.179 29.5486C140.535 29.1755 139.839 28.9889 139.093 28.9889C138.347 28.9889 137.651 29.1755 137.007 29.5486C136.362 29.9048 135.837 30.4391 135.43 31.1514C135.039 31.8468 134.844 32.6779 134.844 33.6446C134.844 34.6114 135.039 35.4594 135.43 36.1887C135.837 36.9181 136.362 37.4778 137.007 37.8679C137.668 38.241 138.364 38.4276 139.093 38.4276C139.839 38.4276 140.535 38.241 141.179 37.8679C141.824 37.4947 142.341 36.952 142.731 36.2396C143.138 35.5103 143.342 34.6623 143.342 33.6955Z"
        fill="#121212"
      />
      <path
        d="M155.165 40.9462C154.063 40.9462 153.07 40.7512 152.189 40.3611C151.324 39.954 150.637 39.4113 150.128 38.7329C149.619 38.0375 149.348 37.2658 149.314 36.4177H152.316C152.367 37.0113 152.646 37.5117 153.155 37.9187C153.681 38.3088 154.334 38.5039 155.114 38.5039C155.928 38.5039 156.556 38.3512 156.997 38.046C157.455 37.7237 157.684 37.3166 157.684 36.8248C157.684 36.299 157.429 35.9089 156.921 35.6545C156.429 35.4001 155.64 35.1202 154.555 34.8149C153.503 34.5266 152.646 34.2467 151.985 33.9754C151.324 33.704 150.747 33.2885 150.255 32.7288C149.78 32.1691 149.543 31.4313 149.543 30.5154C149.543 29.7691 149.763 29.0907 150.204 28.4801C150.645 27.8525 151.273 27.3607 152.087 27.0045C152.918 26.6483 153.868 26.4702 154.936 26.4702C156.53 26.4702 157.811 26.8773 158.778 27.6914C159.762 28.4886 160.287 29.5825 160.355 30.9733H157.455C157.404 30.3458 157.15 29.8454 156.692 29.4723C156.234 29.0992 155.615 28.9126 154.834 28.9126C154.071 28.9126 153.486 29.0568 153.079 29.3451C152.672 29.6334 152.468 30.015 152.468 30.4899C152.468 30.8631 152.604 31.1768 152.875 31.4313C153.147 31.6857 153.478 31.8892 153.868 32.0418C154.258 32.1775 154.834 32.3556 155.598 32.5761C156.615 32.8475 157.446 33.1273 158.091 33.4157C158.752 33.687 159.321 34.0941 159.795 34.6368C160.27 35.1796 160.516 35.9004 160.533 36.7993C160.533 37.5965 160.313 38.3088 159.872 38.9364C159.431 39.5639 158.803 40.0558 157.989 40.412C157.192 40.7682 156.251 40.9462 155.165 40.9462Z"
        fill="#121212"
      />
    </svg>
  );
}

export default MobileLogo;
