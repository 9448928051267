import React, { useState } from "react";
import styles from "./Login.module.css";
import { useForm } from "react-hook-form";
import { usePostDataMutation } from "../redux/services/apiService";
import { setUserInfo } from "../redux/features/authSlice";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../components/LoadingSpinner";
import Close from "../components/Close";
import Logo from "../assets/logo/Logo";
import { isMobile } from "react-device-detect";
import MobileLogo from "../assets/logo/MobileLogo";

const Login = () => {
  const [trigger, { isLoading }] = usePostDataMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [logginIn, setLogginIn] = useState(false);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    trigger({
      ...data,
      domain: window.location.hostname,
    })
      .then((res) => {
        setLogginIn(true);
        dispatch(setUserInfo(res));
        if (res?.data?.code === 0) {
          if (res?.data?.apiResponse?.ssokey) {
            window.location.href = `https://members.greatshoppersavings.com/?ocx=${res?.data?.apiResponse?.ssokey}`;
          } else if (
            res?.data?.apiResponse?.last_order?.data?.code === -1 ||
            !res?.data?.apiResponse?.last_order?.data?.ssokey
          ) {
            setErrorMessage(res?.data?.apiResponse?.last_order?.data?.message);
            setLogginIn(false);
          }
        } else if (res?.error?.data?.code === -1) {
          setLogginIn(false);
          setErrorMessage(res?.error?.data?.message);
        } else if (res?.error) {
          setLogginIn(false);
          setErrorMessage("Something went wrong!");
        }
      })
      .catch((e) => {
        setLogginIn(false);
        setErrorMessage("Something went wrong!");
      });
  };

  return (
    <div className="flex w-full h-[100vh] items-center justify-center">
      <div className="flex flex-col items-center gap-[35.235px] w-[100%]">
        <div className="flex items-center justify-center">
          {isMobile ? <MobileLogo /> : <Logo />}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`flex flex-col pl-[8px] pr-[8px] gap-[48px] w-[334px]`}
          >
            <div className="w-[100%]">
              <div className="flex flex-col gap-[32px]">
                <div className="flex flex-col items-start gap-[6px]">
                  <div className="flex flex-col pl-[24px] pr-[24px] items-start gap-[8px] w-[100%]">
                    <p className="font-semibold text-[14px] leading-[25.48px]">
                      Username
                    </p>
                  </div>
                  <input
                    type="text"
                    {...register("username")}
                    className={`w-[100%] ${styles["inputField"]}`}
                    placeholder="Your Username"
                    id="username"
                    name="username"
                    {...register("username", {
                      required: {
                        value: true,
                        message: "Username Required!",
                      },
                    })}
                  />
                </div>
                <div className="flex flex-col items-start gap-[6px]">
                  <div className="flex flex-col pl-[24px] pr-[24px] items-start gap-[8px] w-[100%]">
                    <p className="font-semibold text-[14px] leading-[25.48px]">
                      Password
                    </p>
                  </div>
                  <input
                    type="password"
                    {...register("password")}
                    className={`w-[100%] ${styles["inputField"]}`}
                    placeholder="Your Password"
                    id="password"
                    name="password"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Password Required!",
                      },
                    })}
                  />
                </div>
                {errorMessage !== "" && (
                  <div
                    className={`bg-transparent border border-blue-300 text-blue-300 px-4 py-3 rounded relative mt-4 ${styles["error-message-bg"]}`}
                    role="alert"
                  >
                    <span
                      className={`${styles["error-message"]} block w-[90%] text-black`}
                    >
                      {errorMessage}
                    </span>
                    <span
                      className="absolute top-0 bottom-0 right-0 px-4 py-3 "
                      onClick={() => setErrorMessage("")}
                    >
                      <Close />
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-[16px]">
              <button className={`${styles["login-btn"]}`} type="submit">
                {isLoading || logginIn ? <LoadingSpinner /> : "Login"}
              </button>
              <button
                className="back-button"
                onClick={() => {
                  window.location.replace("https://greatshoppersavings.com");
                }}
              >
                Go back
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
